import React, { Component } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';


export default class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  openNav = () => {
    if(document.getElementById("menubar") && document.getElementById("menu")) {
      this.setState({
        isOpen: true
      })
      // document.getElementById("menu").style.width = "150px";
      // document.getElementById("menubar").style.width = "150px";

      document.getElementById("menu").style.zIndex = "100";
      document.getElementById("menubar").style.zIndex = "99";
    }
  }

  closeNav = () => {
    if(document.getElementById("menubar") && document.getElementById("menu")) {
      this.setState({
        isOpen: false
      })
      // document.getElementById("menu").style.width = "20px";
      // document.getElementById("menubar").style.width = "20px";

      document.getElementById("menu").style.zIndex = "1";
      document.getElementById("menubar").style.zIndex = "0";
    }
  }

  render() {
    const { activeTab } = this.props
    let { isOpen } = this.state

    return (
      <aside className="menu" id="menu" onMouseOver={this.openNav} onMouseOut={this.closeNav}>
        <ul className="menu-list" id="menu-list">
          <li id='home-tab'><Link className={`menu-tab ${activeTab == 'home-page' ? 'is-active' : ''}`} id='home-tab' to="/"><FontAwesomeIcon icon={faHome} /> {isOpen ? "Home" : null }</Link></li>
          <li id='campaign-tab'><Link className={`menu-tab ${activeTab == 'campaign-page' ? 'is-active' : ''}`} id='campaign-tab' to="/campaigns"><FontAwesomeIcon icon={faFlag} /> {isOpen ? "Campaign" : null }</Link></li>
          <li id='customer-tab'><Link className={`menu-tab ${activeTab == 'customer-page' ? 'is-active' : ''}`} id='customer-tab' to="/customers"><FontAwesomeIcon icon={faUser} /> {isOpen ? "Customer" : null }</Link></li>
          <li id='sales-tab'><Link className={`menu-tab ${activeTab == 'sales-page' ? 'is-active' : ''}`} id='sales-tab' to="/sales"><FontAwesomeIcon icon={faFileInvoiceDollar} /> {isOpen ? "Sales" : null }</Link></li>
          <li id='survey-tab'><Link className={`menu-tab ${activeTab == 'survey-page' ? 'is-active' : ''}`} id='survey-tab' to="/surveys"><FontAwesomeIcon icon={faTabletAlt} /> {isOpen ? "Surveys" : null }</Link></li>
          <li id='lab-tab'><Link className={`menu-tab ${activeTab == 'lab-page' ? 'is-active' : ''}`} id='lab-tab' to="/labs"><FontAwesomeIcon icon={faFlask} /> {isOpen ? "Labs" : null }</Link></li>
          <li id='datalake-tab'><Link className={`menu-tab ${activeTab == 'datalake-page' ? 'is-active' : ''}`} id='datalake-tab' to="/datasets"><FontAwesomeIcon icon={faDatabase} /> {isOpen ? "Datasets" : null }</Link></li>
          <li id='model-tab'><Link className={`menu-tab ${activeTab == 'model-page' ? 'is-active' : ''}`} id='model-tab' to="/models"><FontAwesomeIcon icon={faChartArea} /> {isOpen ? "Models" : null }</Link></li>
          <li id='status-tab'><Link className={`menu-tab ${activeTab == 'status-page' ? 'is-active' : ''}`} id='status-tab' to="/status/overview"><FontAwesomeIcon icon={faHeartbeat} /> {isOpen ? "System Status" : null }</Link></li>
        </ul>
      </aside>
    )
  }
}
