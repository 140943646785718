import React from 'react';
import Helmet from 'react-helmet';
import MenuBar from '@tra-sg/gatsby-theme-c360-portal/src/components/MenuBar';


export default function PageWrapper({ activeTab, metaTitle, children}) {
  // TODO: we have many layers of wrapper that can be unified
  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <section className="section section--gradient">
        <div className="rows">
          <div className="row is-full">
            <div className="columns">
              <div className="column is-hidden-touch menubar" id="menubar">
                <MenuBar activeTab={activeTab}/>
              </div>
              <div
                className="home-main column is-full-mobile is-full-tablet is-full-touch is-full-desktop is-centered"
                style={{maxWidth: 'calc( 100vw - 70px )'}}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}